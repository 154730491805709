import React, { useState, useEffect } from "react";
import "./Menu.css";
import { useOktaAuth } from "@okta/okta-react";

function Header() {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      document.body.classList.remove(
        "compensate-for-scrollbar",
        "is-using-mouse"
      );
    } else {
      document.body.classList.add("compensate-for-scrollbar", "is-using-mouse");
    }
  }, [isActive]);

  const { oktaAuth, authState } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    await oktaAuth.signOut();
  };

  const signInBtn = authState.isAuthenticated ? (
    <button className="header__menu-sso-btn" onClick={logout}>
      Logout?
    </button>
  ) : (
    <button className="header__menu-sso-btn" onClick={login}>
      Sign In
    </button>
  );

  return (
    <div className="header__diageo-menu">
      <div
        onClick={handleToggle}
        className={
          isActive
            ? "header__diageo-menu-btn"
            : "header__diageo-menu-btn header__menu-active"
        }
        id="diageo-menu-toggle"
      >
        <span className="header__menu-line-top"></span>
        <span className="header__menu-line-middle"></span>
        <span className="header__menu-line-bottom"></span>
      </div>
      <div
        className={
          isActive
            ? "header__diageo-overlay"
            : "header__diageo-overlay header__menu-open"
        }
        id="diageo-overlay"
      >
        <div className="header__menu-login">
          <div className="header__menu-btn">{signInBtn}</div>
        </div>
        <nav className="header__diageo-overlay-menu">
          <ul>
            <li>
              <a rel="noreferrer" href="/" aria-label="Back to home page.">
                Home
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/styleguide"
                aria-label="Go to style guide"
              >
                Style Guide
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/smirnoff-blue-raspberry-lemonade"
                aria-label="View the Smirnoff Blue Raspberry Lemonade page."
              >
                Smirnoff Blue Raspberry Lemonade
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/captain-morgan-vita-coco"
                aria-label="View the Captain Morgan Vita Coco page."
              >
                Captain Morgan Vita Coco
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/buchanans-pineapple"
                aria-label="View the Buchanan's Pineapple page."
              >
                Buchanan's Pineapple
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/baileys-vanilla-mint-shake"
                aria-label="View the Baileys Vanilla Mint Shake page."
              >
                Baileys Vanilla Mint Shake
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="/baileys-smores"
                aria-label="View the Baileys S'mores page."
              >
                Baileys S'mores
              </a>
            </li>
            <li>
              <a
                href="/bulleit-crafted-cocktails"
                aria-label="View the Bulleit Crafted Cocktails page."
              >
                Bulleit Crafted Cocktails
              </a>
            </li>
            <li>
              <a
                href="/smirnoff-peach-lemonade"
                aria-label="View the Smirnoff Peach Lemonade page."
              >
                Smirnoff Peach Lemonade
              </a>
            </li>
            <li>
              <a
                href="/crown-royal-whisky-lemonade"
                aria-label="View the Crown Royal Whisky Lemonade page."
              >
                Crown Royal Whisky Lemonade
              </a>
            </li>

            {/* SOCIALS */}
            <li>
              <div className="header__menu-social-icons">
                {/* MENU SOCIAL BTNS */}
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/DiageoInnovation"
                  target="_blank"
                  aria-label="Visit the Diageo Innovation Facebook page."
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://twitter.com/DiageoInnovates"
                  target="_blank"
                  aria-label="Visit the Diageo Innovation Twitter page."
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/diageoinnovation/"
                  target="_blank"
                  aria-label="Visit the Diageo Innovation Instagram page."
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
