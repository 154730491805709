import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./StyleGuide.css";
import "../App.css";
import Accordion from "../components/Accordion.js";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

const StyleGuide = () => {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("style-guide-page");
  }, []);

  const accordionTitle = useRef();
  const accordionTitleTl = useRef();

  useLayoutEffect(() => {
    const accordionTitleAnim = gsap.context(() => {
      accordionTitleTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".style-guide-hero h1",
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".style-guide-hero p",
          {
            autoAlpha: 1,
            y: 0,
            stagger: 0.2,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        );
    }, accordionTitle);
  }, []);

  const accordions = useRef();
  const accordionsTl = useRef();

  useLayoutEffect(() => {
    const accordionsAnim = gsap.context(() => {
      accordionsTl.current = gsap.timeline().to(".accordion__container", {
        autoAlpha: 1,
        y: 0,
        stagger: 0.05,
        duration: 1,
        ease: "power4.inOut",
      });
    }, accordions);
  }, []);

  const accordionData = [
    {
      title: "What's on the Sites",
      content: (
        <>
          <p>
            <strong>Innovation Gateway Page</strong>
          </p>
          <ul>
            <li>Thumbnail cards leading to new and upcoming Innovations</li>
            <li>Innovations sorted by launch date</li>
          </ul>
          <p>
            <strong>Innovation Brand Landing Pages</strong>
          </p>
          <ul>
            <li>Product Hero Images</li>
            {/* <li>Links to Diageo Innovation social profiles for easy sharing</li> */}
            <li>Ready-to-Share Images</li>
            <li>Ready-to-Share Videos & GIFs</li>
            <li>Suggested Copy Options</li>
            <li>Grab-a-Hashtag</li>
            <li>Reviews & Third-Party Media (as available)</li>
          </ul>
        </>
      ),
      accordionId: "whats-on",
    },
    {
      title: "What You Can Do on the Sites",
      content: (
        <ol>
          <li>
            <strong>SHARE</strong> posts with family and friends on Facebook,
            Twitter, Instagram, and LinkedIn.
          </li>
          <li>
            <strong>DOWNLOAD READY-TO-SHARE</strong> Images and use them to
            create your own Social Media posts (Facebook, Twitter, LinkedIn,
            Instagram).
          </li>
          <li>
            <strong>DOWNLOAD READY-TO-SHARE</strong> Videos and GIFs and post
            them on your Social Media pages (Facebook, Twitter, LinkedIn,
            Instagram).
          </li>
        </ol>
      ),
      accordionId: "what-you-can",
    },
    {
      title: "How to Post Content",
      content: (
        <ol>
          <li>
            Select the content you'd like to post. In most cases, we provide
            video and still image content in sizes and formats designed for a
            variety of media types.
          </li>
          <li>
            Select the social media account you'd like to post to. We provide
            one-click content that can be posted to Facebook, Twitter, and
            LinkedIn. We also provide Instagram content that can be posted
            manually.
          </li>
          <li>
            Always include required hashtags, including #DiageoInnovation and
            #IWorkforDiageo or #IWorkfor[BrandName], depending on the content.
          </li>
          <li>
            Select from a variety of provided optional hashtags. Please try
            using at least one or two each time you post.
          </li>
          <li>
            Use the provided copy, or modify it to better match your own style.
          </li>
          <li>
            Click "Post". You may be prompted to sign in to your social account
            if you are not already.
          </li>
        </ol>
      ),
      accordionId: "how-to-post",
    },
    {
      title: "Our Profiles",
      content: (
        <>
          <p>
            Diageo Innovation currently owns and maintains profiles on Facebook,
            Twitter, and Instagram. We encourage all Diageo NA employees to
            Like, Follow, and Share content from these accounts.
          </p>
          <p>
            <strong>FACEBOOK</strong> @DiageoInnovation
          </p>
          <p>
            <strong>TWITTER</strong> @DiageoInnovates
          </p>
          <p>
            <strong>INSTAGRAM</strong> @diageoinnovation
          </p>
        </>
      ),
      accordionId: "our-profiles",
    },
    {
      title: "Voice & Tone",
      content: (
        <>
          <p>
            A brand voice is one of the brand elements that is most effective in
            distinguishing the Diageo Innovation brands from competitors and
            building familiarity with customers. Just like all our other brands,
            consistency is important across all mediums.
          </p>
          <p>
            <strong>
              Characteristics of the Diageo Innovation brand voice are:
            </strong>
          </p>
          <p>
            <strong>CONFIDENT</strong> - But approachable. Try to instill a
            sense of trust and excitement in our brands that encourages people
            to try them.
          </p>
          <p>
            <strong>REAL</strong> - Write like you speak, using everyday
            language. Choose words that are as authentic as our products. Avoid
            clichés, overused metaphors, and "corporate jargon". Be
            conversational and human.
          </p>
          <p>
            <strong>CURIOUS</strong> - At Diageo Innovation, we live at the
            intersection of science and cocktail culture. Innovation is at the
            heart of what we do. Offer your friends and family on social media a
            "peek behind the curtain" for new products they won't find anywhere
            else.
          </p>
          <p>
            <strong>SPIRITED</strong> - Add a positive buzz to your writing;
            keep it pleasant and engaging. Offer a sneak peak with advance
            information that builds excitement and curiosity about our newest
            innovations.
          </p>
          <p>
            <strong>OPTIMISTIC</strong> - Be genuinely excited about the
            potential of each of our new product innovations! Take every
            opportunity to share them in an inspiring and energetic way with an
            air of possibility.
          </p>
          <p>
            <strong>APPROPRIATE</strong> - Tailor your language between social
            channels. Just as you might be a bit more reserved in the way you
            present yourself at work than among close friends, it's similar on
            social. For example, your LinkedIn audience may take a more
            professional tone while your Facebook or Instagram social content
            has a more casual look and feel.
          </p>
        </>
      ),
      accordionId: "voice-tone",
    },
    {
      title: "Language & Grammar",
      content: (
        <>
          <p>
            We adhere to AP Style guidelines for post content. Social, however,
            is a great place to loosen the necktie a bit. For instance, you may
            avoid the use of contractions in your corporate communication, but
            that doesn't always flow like a normal conversation. We're trying to
            build more human connections on social media and may have a limited
            character count, so do what feels natural.
          </p>
          <ul>
            <li>Avoid using industry or Diageo terminology or jargon.</li>
            <li>
              Avoid abbreviations unless they are widely understood outside of
              the TBA industry (USA, ATM, ABV, etc.).
            </li>
            <li>
              Avoid the use of exclamation marks to convey excitement. Let your
              words generate the buzz.
            </li>
            <li>
              Don't use swear words or language that may offend or be considered
              inappropriate.
            </li>
            <li>
              Don't promote drinking to excess, smoking, illicit drugs, drunk
              driving, or other irresponsible activities.
            </li>
          </ul>
        </>
      ),
      accordionId: "language-grammar",
    },
    {
      title: "Post Formatting",
      content: (
        <>
          <div className="facebook-formatting">
            <h4>FACEBOOK</h4>
            <p>
              <strong>POSTING DIRECTLY</strong>
              <br />
              As the most used, diverse platform, Facebook posts can be short
              and sweet, or longer in-depth writing, whatever best suits your
              content. Include strong imagery, links to websites, and keep your
              type at a moderate length.
            </p>
            <ul>
              <li>Properly tag others.</li>
              <li>
                Insert website links at the end of the post to not distract from
                your writing (not always necessary).
              </li>
              <li>
                Use high-performing and appropriate hashtags. Hashtags should be
                put at the end of the post.
              </li>
              <li>Recommended hashtags: 2-3</li>
            </ul>
            {/* <p><strong>SHARING CONTENT</strong><br/>
            When sharing another page's content on Facebook, remember to add your own comment with the original post. That way content will not be duplicative and maintain your identity while supporting the brand.</p>
            <ul>
                <li>Properly tag others.</li>
                <li>Insert links to websites at the end of the post (not always necessary).</li>
                <li>Use applicable hashtags at the end of the post.</li>
                <li>Recommended hashtags: 2-3</li>
            </ul> */}
          </div>
          <div className="twitter-formatting">
            <h4>TWITTER</h4>
            <p>
              <strong>POSTING DIRECTLY</strong>
              <br />
              With a limit of 280 characters, Twitter asks for "crisp and
              clever" content. Use strong imagery to get users to stop scrolling
              through the 5 million other tweets put out daily.
            </p>
            <ul>
              <li>Properly tag others.</li>
              <li>
                Insert links to websites at the end of the post (not always
                necessary).
              </li>
              <li>Use applicable hashtags at the end of the post.</li>
              <li>Recommended hashtags: 2-3</li>
            </ul>
            {/* <p><strong>SHARING CONTENT</strong><br/>
                        You can retweet directly with no content and keep original imagery or retweet with a comment that allows you to add context but could possibly hide imagery. Experiment with both techniques to see what your audience tends to interact with the most.</p> */}
          </div>
          <div className="instagram-formatting">
            <h4>INSTAGRAM</h4>
            <p>
              <strong>POSTING DIRECTLY</strong>
              <br />
              People want to see beautiful pictures on Instagram, not read text.
              Keep text simple and complimentary to your imagery. Instagram does
              not allow you to link to websites in the post.
            </p>
            <ul>
              <li>Tag others within the photo.</li>
              <li>Use applicable hashtags at the end of the post.</li>
              <li>Recommended hashtags: 2-6</li>
            </ul>
            {/* <p><strong>SHARING CONTENT</strong><br/>
                        Sharing content is very simple on Instagram. Avoid regram apps and instead, screengrab photos and give credit within the post by using their tagline and tagging them in the photo.</p> */}
          </div>
          <div className="linkedin-formatting">
            <h4>LINKEDIN</h4>
            <p>
              <strong>POSTING DIRECTLY</strong>
              <br />
              Deemed as the largest social network for professionals and
              colleagues. LinkedIn manages your professional social connections
              and content to like-minded individuals. Overarching best practices
              include audience-specific content, strong imagery, and employee
              sharing and engagement.
            </p>
            <ul>
              <li>Properly tag others.</li>
              <li>
                Use high-performing and appropriate hashtags. Hashtags should be
                a part of the content rather than at the end of the post on
                LinkedIn.
              </li>
              <li>Recommended hashtags: 2-4</li>
            </ul>
            {/* <p><strong>SHARING CONTENT</strong><br/>
                        Diageo Innovation does not have a dedicated LinkedIn page, but we welcome all employees to post content directly to LinkedIn.</p> */}
          </div>
        </>
      ),
      accordionId: "post-formatting",
    },
    {
      title: "Hashtag Usage",
      content: (
        <>
          <p>
            Hashtags are very important. They allow us to track conversations
            across social media channels, and measure the engagement and reach
            of our content.
          </p>
          <p>
            <strong>REQUIRED HASHTAGS</strong>
            Required hashtags are provided for you on each Innovation page.
            These will include a variation of #IWorkforDiageo if posting general
            or multibrand content or #IWorkfor[BrandName] if posting content
            about a single brand. Please also include our primary branded
            hashtag #DiageoInnovation, to build familiarity with our audience.
          </p>

          <p>
            <strong>OPTIONAL HASHTAGS</strong>
            Other recommended hashtags will vary depending on the post content
            and will be included with the content on each landing page within
            our microsite. We highly encourage everyone to include as many of
            the optional hashtags provided with each post as they can.
          </p>

          <p>
            Please see our
            <a
              className="post-formatting-link"
              href="#post-formatting"
              aria-label="See post formatting information."
            >
              POST FORMATTING
            </a>
            section for the optimal number of hashtags for each social media
            platform.
          </p>
        </>
      ),
      accordionId: "hashtag-usage",
    },
    {
      title: "Transparency",
      content: (
        <>
          <p>
            We must be transparent about our identity in marketing
            communications and a promoter, endorser, influencer, spokesperson or
            partner must fully, clearly, and conspicuously disclose any
            association with Diageo, so that consumers are left in no doubt as
            to that association.
          </p>
          <p>
            Diageo employees, agency or partner employees, brand ambassadors,
            and other Diageo representatives and partners must clearly and
            conspicuously disclose their association with Diageo and must not
            pretend to be a consumer or an ordinary member of the public when
            creating buzz about our brands.
          </p>
          <p>
            A transparency statement indicating that the individual works for
            Diageo/is a brand ambassador, e.g. "Diageo brand ambassador. Views
            are my own" or "Opinions are mine"
          </p>
          <p>
            Any individual post made by a Diageo brand ambassador relating to
            our products should include a transparency statement indicating that
            the individual works for Diageo/is a brand ambassador and
            appropriate hashtags.
          </p>
          <p>
            Note that a variation of #IWorkforDiageo or #IWorkfor[BrandName]
            should be shown on all posts as the first hashtag.
          </p>
        </>
      ),
      accordionId: "transparency",
    },
    {
      title: "Interactions With Customers & Competitors",
      content: (
        <>
          <p>
            Let's keep this simple. Play nice. Have fun, and interact
            respectfully and considerately with those who engage with your
            posts.
          </p>
          <p>
            However, avoid being sarcastic or mocking toward customers,
            followers, fellow employees, or other brands - including and
            especially competitors.
          </p>
        </>
      ),
      accordionId: "interactions",
    },
    {
      title: "Dos and Don'ts",
      content: (
        <>
          <ul>
            <li>
              DO make sure that what you post is factually correct and legally
              permissible, and isn't defamatory, threatening, abusive,
              discriminatory, offensive, an invasion of privacy, or an
              infringement of any third party's copyright or other intellectual
              property rights.
            </li>
            <li>
              DO adhere to our community guidelines. You will find that our page
              is moderated regularly to make sure that the community guidelines
              are being adhered to. We may remove comments that violate our
              Community Guidelines.
            </li>
            <li>
              DO report a comment on our page that you consider inappropriate.
            </li>
            <li>DO comply with the platform or website's Terms of Use.</li>
            <li>DO always remember to Drink Responsibly.</li>
            <li>
              DON'T upload pictures of people under 25 (including yourself).
            </li>
            <li>DON'T post sexually suggestive text or pictures.</li>
            <li>
              DON'T promote drinking to excess, smoking, illicit drugs, drink
              driving, or other irresponsible activities.
            </li>
            <li>
              DON'T post names, photos, or audio/video content on our page if a
              person or organization hasn't given you express permission to use
              it.
            </li>
            <li>
              DON'T post any advertising, spam, or links to external sites. This
              includes posts used in an attempt to direct traffic to other
              websites, blogs, or pages.
            </li>
            <li>
              DON'T post personal information about yourself that should remain
              private.
            </li>
          </ul>
        </>
      ),
      accordionId: "dos-dont",
    },
    {
      title: "Right to Remove",
      content: (
        <>
          <p>
            We reserve the right to remove comments at any time for any reason
            without prior notice. Diageo is not responsible or liable for any
            content posted or uploaded to this site by its users, or any content
            on third-party sites.
          </p>
        </>
      ),
      accordionId: "right-to-remove",
    },
    {
      title: "Forward Advice Notice",
      content: (
        <>
          <p>
            Please note that this content is intended for adults. Please do not
            forward the content on this page to anyone under the legal purchase
            age of your country.
          </p>
        </>
      ),
      accordionId: "forward-advice",
    },
    {
      title: "DRINKiQ",
      content: (
        <>
          <a href="https://www.drinkiq.com">https://www.drinkiq.com</a>
        </>
      ),
      accordionId: "drinkiq",
    },
    {
      title: "Questions",
      content: (
        <>
          <p>
            For questions about using these social media resources, or to report
            any misuse or violations in our community standards, please contact:
          </p>
          <a href="mailto:diageoinnovation@holmesmillet.com">
            diageoinnovation@holmesmillet.com
          </a>
        </>
      ),
      accordionId: "questions",
    },
  ];

  return (
    <>
      <section
        ref={accordionTitle}
        className="style-guide-hero style-guide-half black-bg"
      >
        <h1>SOCIAL MEDIA STYLE GUIDE</h1>
        <p>
          <em>
            Thanks for your help in building our brands by SHARING them on your
            social media. Here are a few tips to make sure we are all speaking
            with the same voice.
          </em>
        </p>
        <p>
          The top brands on social media all have something in common:
          consistency. This style guide will help you help us remain among those
          top brands. It explains how Diageo brands should appear across all
          social channels and includes visual and writing guidelines for how to
          present our brands in a way that maintains a cohesive presence. We are
          in this together.
        </p>
      </section>
      <section ref={accordions}>
        {/* RETURN ALL ACCORDION DATA */}
        {accordionData.map(({ title, content, accordionId }) => (
          <Accordion
            key={accordionId}
            title={title}
            content={content}
            accordionId={accordionId}
          />
        ))}
      </section>

      <div className="spacer black-bg"></div>
    </>
  );
};

export default StyleGuide;
