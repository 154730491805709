import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Brands.css";
import "../App.css";
import * as FaIcons from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tips from "../components/Tips.js";
import BrandsSlider from "../components/BrandsSlider.js";
import Fancybox from "../components/Fancybox.js";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

function BuchanansPineapple() {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("buchanans-pineapple-page");
  }, []);

  // BRAND HERO ANIMATION
  const brand = useRef();
  const brandTl = useRef();

  useLayoutEffect(() => {
    const brandAnim = gsap.context(() => {
      brandTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".brand__brand-hero",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".brand__play-btn",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        );
    }, brand);
  }, []);

  // BRAND INTRO
  const brandIntro = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandIntro = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__intro",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandIntro.to(".brand__intro-wrap", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandIntro.scrollTrigger.kill();
    });
  }, []);

  // BRAND SHARE
  const brandShare = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandShare = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__share",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandShare.to(".brand__share-text", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandShare.scrollTrigger.kill();
    });
  }, []);

  // BRAND VIDEO
  const brandVideo = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandVideo = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__video-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandVideo.add("start");
      brandVideo.to(
        ".brand__video-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandVideo.to(
        ".brand__video-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandVideo.scrollTrigger.kill();
    });
  }, []);

  // BRAND IMAGES
  const brandImages = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandImages = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__image-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandImages.add("start");
      brandImages.to(
        ".brand__image-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandImages.to(
        ".brand__image-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandImages.scrollTrigger.kill();
    });
  }, []);

  // BRAND HASHTAGS
  const brandHashtags = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandHashtags = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__hashtag-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandHashtags.add("start");
      brandHashtags.to(
        ".brand__hashtag-heading",
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandHashtags.to(
        ".brand__hashtag",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandHashtags.scrollTrigger.kill();
    });
  }, []);

  // BRAND COPY
  const brandCopy = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandCopy = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__message-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandCopy.add("start");
      brandCopy.to(
        ".brand__message-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandCopy.to(
        ".brand__message",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandCopy.scrollTrigger.kill();
    });
  }, []);

  // BRAND TIPS
  const brandTips = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandTips = gsap.timeline({
        scrollTrigger: {
          trigger: ".tips__section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandTips.add("start");
      brandTips.to(
        ".tips__heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandTips.to(
        ".tips__block",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandTips.scrollTrigger.kill();
    });
  }, []);

  // BRAND SLIDER
  const brandSlider = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandSlider = gsap.timeline({
        scrollTrigger: {
          trigger: ".brandsslider__related",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandSlider.add("start");
      brandSlider.to(
        ".brandsslider__related-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related-cell a",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related .dil-btn",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandSlider.scrollTrigger.kill();
    });
  }, []);

  // Copy Functions
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const [isCopied2, setIsCopied2] = useState(false);

  const onCopyText2 = () => {
    setIsCopied2(true);
    setTimeout(() => {
      setIsCopied2(false);
    }, 1000);
  };

  const [isCopied3, setIsCopied3] = useState(false);

  const onCopyText3 = () => {
    setIsCopied3(true);
    setTimeout(() => {
      setIsCopied3(false);
    }, 1000);
  };

  const [isCopied4, setIsCopied4] = useState(false);

  const onCopyText4 = () => {
    setIsCopied4(true);
    setTimeout(() => {
      setIsCopied4(false);
    }, 1000);
  };

  const [isCopied5, setIsCopied5] = useState(false);

  const onCopyText5 = () => {
    setIsCopied5(true);
    setTimeout(() => {
      setIsCopied5(false);
    }, 1000);
  };

  const [isCopied6, setIsCopied6] = useState(false);

  const onCopyText6 = () => {
    setIsCopied6(true);
    setTimeout(() => {
      setIsCopied6(false);
    }, 1000);
  };

  const [isCopied7, setIsCopied7] = useState(false);

  const onCopyText7 = () => {
    setIsCopied7(true);
    setTimeout(() => {
      setIsCopied7(false);
    }, 1000);
  };

  const [isCopied8, setIsCopied8] = useState(false);

  const onCopyText8 = () => {
    setIsCopied8(true);
    setTimeout(() => {
      setIsCopied8(false);
    }, 1000);
  };

  const [isCopied9, setIsCopied9] = useState(false);

  const onCopyText9 = () => {
    setIsCopied9(true);
    setTimeout(() => {
      setIsCopied9(false);
    }, 1000);
  };

  const [isCopied10, setIsCopied10] = useState(false);

  const onCopyText10 = () => {
    setIsCopied10(true);
    setTimeout(() => {
      setIsCopied10(false);
    }, 1000);
  };

  return (
    <>
      {/* HERO */}
      <section ref={brand} className="brand__hero">
        <img
          className="brand__mobile-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Buchanan's-Pineapple-Posts-4x5.jpg"
          alt="Buchanan's Pineapple Hero Mobile"
          title="Buchanan's Pineapple Hero Mobile"
        />
        <img
          className="brand__desktop-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Buchanan's-Pineapple-Posts-16x9.jpg"
          alt="Buchanan's Pineapple Hero Desktop"
          title="Buchanan's Pineapple Hero Desktop"
        />
        <Fancybox>
          <a
            rel="noreferrer"
            className="brand__play-btn"
            data-fancybox
            href="https://vimeo.com/791335512"
            aria-label="Play Buchanan's Pineapple sizzle reel."
          >
            <div className="brand__icon green-gradient-bg-3">
              <FaIcons.FaPlay className=" white" />
            </div>
          </a>
        </Fancybox>
      </section>
      {/* INTRO */}
      <section
        ref={brandIntro}
        className="full brand__intro buchanan-yellow-bg"
      >
        <div className="brand__intro-wrap">
          <h3 className="brand__intro-heading buchanan-green">
            <span className="buchanan-green">Introducing</span> <br />
            BUCHANAN'S PINEAPPLE <br />
            <span className="brand__intro-subtitle buchanan-light-green">
              LIMITED TIME ONLY
            </span>
          </h3>

          <p className="brand__intro-copy buchanan-green">
            Buchanan's Pineapple combines the extraordinary smoothness of Scotch
            Whisky from the house of Buchanan's with the vibrant taste of
            luscious pineapple. This spring, enjoy the uniquely sophisticated
            aroma of juicy tropical flavors paired with light caramel and
            vanilla notes for zesty citrus perfection. 100% scotch tradition.
            100% pina vibes.
          </p>

          <p className="brand__intro-copy buchanan-green">
            Enjoy in a pina colada, on ice, or mixed with seltzer water
            garnished with an orange slice.
          </p>
          <p>
            <a
              rel="noreferrer"
              className="dil-btn"
              href="/styleguide"
              aria-label="Go to style guide"
            >
              Style Guide
            </a>
          </p>
        </div>
      </section>

      {/* SHARE BUTTONS */}
      <section
        ref={brandShare}
        className="full brand__share green-gradient-bg-3"
      >
        <div className="brand__share-wrap">
          <div className="brand__share-text">
            <h3 className="white">
              <span className="partial-op">Help build our</span>{" "}
              <span className="full-op">brands</span>{" "}
              <span className="partial-op">by</span>{" "}
              <span className="full-op">SHARING</span>{" "}
              <span className="partial-op">them on your</span>{" "}
              <span className="full-op">social media.</span>
            </h3>
          </div>
          <div className="brand__share-feature">
            <h4 className="brand__share-heading heading lime-green">
              Quick Share From Our Social Accounts
            </h4>
            {/* NEW SHARE BUTTONS */}
            <a
              rel="noreferrer"
              href="https://www.facebook.com/DiageoInnovation"
              target="_blank"
              aria-label="Share Buchanan's Pineapple from Facebook."
            >
              <i className="lime-green fab fa-facebook-f"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/DiageoInnovates"
              target="_blank"
              aria-label="Share Buchanan's Pineapple from Twitter."
            >
              <i className="lime-green fab fa-twitter"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/diageoinnovation/"
              target="_blank"
              aria-label="Share Buchanan's Pineapple from Instagram."
            >
              <i className="lime-green fab fa-instagram"></i>
            </a>

            {/* FORMER SHARE BUTTONS 
                  <a rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https://www.baileys.com/en-us/" aria-label="Share Buchanan's Pineapple on Facebook."><i className="lime-green fab fa-facebook"></i></a>
                  <a rel="noreferrer" href="https://twitter.com/intent/tweet?url=https://www.baileys.com/en-us/&text=" aria-label="Share Buchanan's Pineapple on Twitter."><i className="lime-green fab fa-twitter"></i></a>
                  <a rel="noreferrer" href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.baileys.com/en-us/" aria-label="Share Buchanan's Pineapple on LinkedIn."><i className="lime-green fab fa-linkedin-in"></i></a> */}
          </div>
        </div>
      </section>

      {/* VIDEO DOWNLOADS */}
      <section
        ref={brandVideo}
        id="video-downloads"
        className="full brand__video-downloads buchanan-yellow-bg"
      >
        <h4 className="brand__video-download-heading heading buchanan-green">
          <span className="buchanan-light-green">DOWNLOAD</span> these
          READY-TO-SHARE <span className="buchanan-light-green">Videos.</span>
        </h4>
        <div className="brand__video-downloads-wrapper" video-rollover="1">
          <div className="brand__video-item">
            <div
              id="brand__video-item-1"
              className="brand__download-wrap brand__video-rollover buchanan-light-yellow-bg"
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-1x1.jpg"
                alt="Buchanan's Pineapple 1x1 Thumbnail"
                title="Buchanan's Pineapple 1x1 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335482/rendition/1080p/buchanan%27s-pineapple-1x1.mp4%20%281080p%29.mp4?loc=external&signature=85968db7032fce624e18e6f67e3c8dfa9badb71b927e9f71f9c6b624f9bd6a7f"
                  download
                  aria-label="Download Buchanan's Pineapple 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335482"
                  aria-label="Preview Buchanan's Pineapple 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong class="buchanan-green">Square 1x1</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-2"
              className="brand__download-wrap brand__video-rollover buchanan-light-yellow-bg"
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-9x16.jpg"
                alt="Buchanan's Pineapple 9x16 Thumbnail"
                title="Buchanan's Pineapple 9x16 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335505/rendition/720p/buchanan%27s-pineapple-9x16%20%28720p%29.mp4?loc=external&signature=c6069e6ac4d3140d5f5fe63c549d709e39a70927203e96b11c07152d5c512e29"
                  download
                  aria-label="Download Buchanan's Pineapple 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335505"
                  aria-label="Preview Buchanan's Pineapple 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong class="buchanan-green">Vertical 9x16</strong>
              <br />
              <em className="buchanan-green">Facebook or Instagram Stories</em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-3"
              className="brand__download-wrap brand__video-rollover buchanan-light-yellow-bg"
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-4x5.jpg"
                alt="Buchanan's Pineapple 4x5 Thumbnail"
                title="Buchanan's Pineapple 4x5 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335495/rendition/1080p/buchanan%27s-pineapple-4x5%20%281080p%29.mp4?loc=external&signature=ec935f93a1ff0c18ae0759989541bfc92ae1b1eb23475684ec14b692c73478fe"
                  download
                  aria-label="Download Buchanan's Pineapple 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335495"
                  aria-label="Preview Buchanan's Pineapple 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong class="buchanan-green">Vertical 4x5</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-4"
              className="brand__download-wrap brand__video-rollover buchanan-light-yellow-bg"
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-16x9.jpg"
                alt="Buchanan's Pineapple 16x9 Thumbnail"
                title="Buchanan's Pineapple 16x9 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335512/rendition/720p/buchanan%27s-pineapple-16x19%20%28720p%29.mp4?loc=external&signature=a22f7214bd24ac1e934e33ab55394ffc77cf4fa459c4e2afe848833ce550cde9"
                  download
                  aria-label="Download Buchanan's Pineapple 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335512"
                  aria-label="Preview Buchanan's Pineapple 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong class="buchanan-green">Horizontal 16x9</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* IMAGE DOWNLOADS */}
      <section
        ref={brandImages}
        className="full brand__image-downloads buchanan-light-yellow-bg"
      >
        <h4 className="brand__image-download-heading heading buchanan-green">
          <span className="buchanan-light-green">DOWNLOAD</span> these
          READY-TO-SHARE <span className="buchanan-light-green">Images.</span>
        </h4>

        <div className="brand__image-downloads-wrapper">
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap buchanan-yellow-bg"
              href="/assets/images/Buchanan's-Pineapple-Posts-1x1.jpg"
              aria-label="Download Buchanan's Pineapple 1x1 image"
              download
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-1x1.jpg"
                alt="Buchanan's Pineapple 1x1 thumbnail"
                title="Buchanan's Pineapple 1x1 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong class="buchanan-green">Square 1x1</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap buchanan-yellow-bg"
              href="/assets/images/Buchanan's-Pineapple-Posts-9x16.jpg"
              aria-label="Download Buchanan's Pineapple 9x16 image"
              download
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-9x16.jpg"
                alt="Buchanan's Pineapple 9x16 thumbnail"
                title="Buchanan's Pineapple 9x16 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong class="buchanan-green">Vertical 9x16</strong>
              <br />
              <em className="buchanan-green">Facebook or Instagram Stories</em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap buchanan-yellow-bg"
              href="/assets/images/Buchanan's-Pineapple-Posts-4x5.jpg"
              aria-label="Download Buchanan's Pineapple 4x5 image"
              download
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-4x5.jpg"
                alt="Buchanan's Pineapple 4x5 thumbnail"
                title="Buchanan's Pineapple 4x5 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong class="buchanan-green">Vertical 4x5</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap buchanan-yellow-bg"
              href="/assets/images/Buchanan's-Pineapple-Posts-16x9.jpg"
              aria-label="Download Buchanan's Pineapple 16x9 image"
              download
            >
              <img
                src="/assets/images/Buchanan's-Pineapple-Posts-16x9.jpg"
                alt="Buchanan's Pineapple 16x9 thumbnail"
                title="Buchanan's Pineapple 16x9 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong class="buchanan-green">Horizontal 16x9</strong>
              <br />
              <em className="buchanan-green">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* HASHTAG SECTION */}
      <section
        ref={brandHashtags}
        className="full brand__hashtag-section green-gradient-bg-3"
      >
        <div className="brand__required-hashtag">
          <h4 className="brand__hashtag-heading heading white">
            REQUIRED HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#IWorkForBuchanans</strong>
              </p>
              <CopyToClipboard text="#IWorkForBuchanans" onCopy={onCopyText}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText2}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied2 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="grab-hashtag">
          <h4 className="brand__hashtag-heading heading white">
            OPTIONAL HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText3}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied3 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#FromtheLab</strong>
              </p>
              <CopyToClipboard text="#FromtheLab" onCopy={onCopyText4}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied4 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#CocktailCulture</strong>
              </p>
              <CopyToClipboard text="#CocktailCulture" onCopy={onCopyText5}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied5 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#BuchanansPineapple</strong>
              </p>
              <CopyToClipboard text="#BuchanansPineapple" onCopy={onCopyText6}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied6 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#Buchanans</strong>
              </p>
              <CopyToClipboard text="#Buchanans" onCopy={onCopyText7}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied7 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </section>

      {/* MESSAGE SECTION */}
      <section
        ref={brandCopy}
        className="full brand__message-section buchanan-yellow-bg"
      >
        <h4 className="brand__message-heading heading">
          <span className="buchanan-green">
            NEED MESSAGE POINTS ABOUT THIS INNOVATION?
          </span>
        </h4>
        <div className="brand__message-wrapper">
          <div className="brand__copy-block brand__message">
            <h4 className="buchanan-green">News Style</h4>

            <p className="buchanan-green" id="NewsStyle">
              NEW this fall from Diageo Innovation - BUCHANAN’S PINEAPPLE. This
              tropical drink combines the extraordinary smoothness of Scotch
              Whisky from the house of Buchanan’s with the vibrant taste of
              luscious pineapple. Available for a limited time.
            </p>
            <CopyToClipboard
              text="NEW this fall from Diageo Innovation - BUCHANAN’S PINEAPPLE. This tropical drink combines the extraordinary smoothness of Scotch Whisky from the house of Buchanan’s with the vibrant taste of luscious pineapple. Available for a limited time."
              onCopy={onCopyText8}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied8 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="buchanan-green">Casual</h4>

            <p className="buchanan-green" id="Casual">
              A perfect balance of Scotch Whisky and tropical flavors,
              Buchanan’s Pineapple is the latest release from Diageo Innovation.
              An extraordinary combination of Scotch Whisky from the house of
              Buchanan’s with the vibrant taste of luscious pineapple. Just
              garnish with an orange slice for a tropical sensation. 100% scotch
              tradition. 100% pina vibes. Available for a limited time.
            </p>
            <CopyToClipboard
              text="A perfect balance of Scotch Whisky and tropical flavors, Buchanan’s Pineapple is the latest release from Diageo Innovation. An extraordinary combination of Scotch Whisky from the house of Buchanan’s with the vibrant taste of luscious pineapple. Just garnish with an orange slice for a tropical sensation. 100% scotch tradition. 100% pina vibes. Available for a limited time."
              onCopy={onCopyText9}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied9 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="buchanan-green">Short & Fun</h4>

            <p className="buchanan-green" id="ShortFun">
              A tropical take on a sophisticated drink, Buchanan’s Pinneapple
              combines extraordinarily smooth Scotch Whisky with luscious and
              juicy pineapple. 100% scotch tradition 100% pina vibes. Brought to
              perfection with an orange slice and available for a limited time
              only.
            </p>
            <CopyToClipboard
              text="A tropical take on a sophisticated drink, Buchanan’s Pinneapple combines extraordinarily smooth Scotch Whisky with luscious and juicy pineapple. 100% scotch tradition 100% pina vibes. Brought to perfection with an orange slice and available for a limited time only."
              onCopy={onCopyText10}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied10 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </section>

      {/* TIPS */}
      <Tips ref={brandTips} />

      {/* BRANDS SLIDER */}
      <BrandsSlider ref={brandSlider} />
    </>
  );
}

export default BuchanansPineapple;
