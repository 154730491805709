import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import SmirnoffBlueRaspberryLemonade from "./pages/SmirnoffBlueRaspberryLemonade";
import CaptainMorganVitaCoco from "./pages/CaptainMorganVitaCoco";
import BaileysSmores from "./pages/BaileysSmores";
import BaileysVanillaMintShake from "./pages/BaileysVanillaMintShake";
import BuchanansPineapple from "./pages/BuchanansPineapple";
import BulleitCraftedCocktails from "./pages/BulleitCraftedCocktails";
import CrownRoyalWhiskyLemonade from "./pages/CrownRoyalWhiskyLemonade";
import SmirnoffPeachLemonade from "./pages/SmirnoffPeachLemonade";
import StyleGuide from "./pages/StyleGuide";
import Notice from "./components/Notice";
import { Route } from "react-router-dom";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";

const oktaAuth = new OktaAuth({
  /* Production Instructions 
    change the domain in package.json, issuer domain, clientid, domain in login.js 
    
    Don't forget to update urls in package.json

    LOCALHOST - http://localhost:3000
    DEV - https://dgo-react.hmidev.net
    STAGING - https://diageoinnovation.diageoplatform.com
    PROD - https://www.diageoinnovation.com or https://diageoinnovation.com
  */

  // LOCAL
  // issuer: "https://dev-644740.okta.com/oauth2/default",
  // clientId: "0oa96nntkueaIHYma4x7",
  // DEV
  issuer: "https://dev-644740.okta.com/oauth2/default",
  clientId: "0oa9kfl582m9jxDvt4x7",
  // STAGING
  // issuer: "https://diageo.okta.com/oauth2/default",
  // clientId: "0oak9e1wst1qAUXxN2p7",
  // PRODUCTION
  // issuer: "https://diageo.okta.com/oauth2/default",
  // clientId: "0oakghs7l1Pr4uY0o2p7",
  redirectUri: window.location.origin + "/callback",
});

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const onAuthRequired = function () {
    history.push("/");
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <Header />
      <main>
        <Notice />
        <SecureRoute path="/" exact={true} component={Home} />

        <SecureRoute
          path="/smirnoff-blue-raspberry-lemonade"
          exact={true}
          component={SmirnoffBlueRaspberryLemonade}
        />
        <SecureRoute
          path="/captain-morgan-vita-coco"
          exact={true}
          component={CaptainMorganVitaCoco}
        />
        <SecureRoute
          path="/buchanans-pineapple"
          exact={true}
          component={BuchanansPineapple}
        />
        <SecureRoute
          path="/baileys-vanilla-mint-shake"
          exact={true}
          component={BaileysVanillaMintShake}
        />
        <SecureRoute
          path="/baileys-smores"
          exact={true}
          component={BaileysSmores}
        />
        <SecureRoute
          path="/bulleit-crafted-cocktails"
          exact={true}
          component={BulleitCraftedCocktails}
        />
        <SecureRoute
          path="/crown-royal-whisky-lemonade"
          exact={true}
          component={CrownRoyalWhiskyLemonade}
        />
        <SecureRoute
          path="/smirnoff-peach-lemonade"
          exact={true}
          component={SmirnoffPeachLemonade}
        />
        <SecureRoute path="/styleguide" exact={true} component={StyleGuide} />
        <Route path="/callback" component={LoginCallback} />
      </main>
      <Footer />
    </Security>
  );
};

export default App;
