import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Brands.css";
import "../App.css";
import * as FaIcons from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tips from "../components/Tips.js";
import BrandsSlider from "../components/BrandsSlider.js";
import Fancybox from "../components/Fancybox.js";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

function BaileysVanillaMintShake() {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("baileys-vanilla-mint-shake-page");
  }, []);

  // BRAND HERO ANIMATION
  const brand = useRef();
  const brandTl = useRef();

  useLayoutEffect(() => {
    const brandAnim = gsap.context(() => {
      brandTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".brand__brand-hero",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".brand__play-btn",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        );
    }, brand);
  }, []);

  // BRAND INTRO
  const brandIntro = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandIntro = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__intro",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandIntro.to(".brand__intro-wrap", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandIntro.scrollTrigger.kill();
    });
  }, []);

  // BRAND SHARE
  const brandShare = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandShare = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__share",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandShare.to(".brand__share-text", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandShare.scrollTrigger.kill();
    });
  }, []);

  // BRAND VIDEO
  const brandVideo = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandVideo = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__video-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandVideo.add("start");
      brandVideo.to(
        ".brand__video-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandVideo.to(
        ".brand__video-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandVideo.scrollTrigger.kill();
    });
  }, []);

  // BRAND IMAGES
  const brandImages = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandImages = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__image-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandImages.add("start");
      brandImages.to(
        ".brand__image-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandImages.to(
        ".brand__image-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandImages.scrollTrigger.kill();
    });
  }, []);

  // BRAND HASHTAGS
  const brandHashtags = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandHashtags = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__hashtag-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandHashtags.add("start");
      brandHashtags.to(
        ".brand__hashtag-heading",
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandHashtags.to(
        ".brand__hashtag",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandHashtags.scrollTrigger.kill();
    });
  }, []);

  // BRAND COPY
  const brandCopy = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandCopy = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__message-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandCopy.add("start");
      brandCopy.to(
        ".brand__message-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandCopy.to(
        ".brand__message",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandCopy.scrollTrigger.kill();
    });
  }, []);

  // BRAND TIPS
  const brandTips = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandTips = gsap.timeline({
        scrollTrigger: {
          trigger: ".tips__section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandTips.add("start");
      brandTips.to(
        ".tips__heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandTips.to(
        ".tips__block",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandTips.scrollTrigger.kill();
    });
  }, []);

  // BRAND SLIDER
  const brandSlider = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandSlider = gsap.timeline({
        scrollTrigger: {
          trigger: ".brandsslider__related",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandSlider.add("start");
      brandSlider.to(
        ".brandsslider__related-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related-cell a",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related .dil-btn",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandSlider.scrollTrigger.kill();
    });
  }, []);

  // Copy Functions
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const [isCopied2, setIsCopied2] = useState(false);

  const onCopyText2 = () => {
    setIsCopied2(true);
    setTimeout(() => {
      setIsCopied2(false);
    }, 1000);
  };

  const [isCopied3, setIsCopied3] = useState(false);

  const onCopyText3 = () => {
    setIsCopied3(true);
    setTimeout(() => {
      setIsCopied3(false);
    }, 1000);
  };

  const [isCopied4, setIsCopied4] = useState(false);

  const onCopyText4 = () => {
    setIsCopied4(true);
    setTimeout(() => {
      setIsCopied4(false);
    }, 1000);
  };

  const [isCopied5, setIsCopied5] = useState(false);

  const onCopyText5 = () => {
    setIsCopied5(true);
    setTimeout(() => {
      setIsCopied5(false);
    }, 1000);
  };

  const [isCopied6, setIsCopied6] = useState(false);

  const onCopyText6 = () => {
    setIsCopied6(true);
    setTimeout(() => {
      setIsCopied6(false);
    }, 1000);
  };

  const [isCopied7, setIsCopied7] = useState(false);

  const onCopyText7 = () => {
    setIsCopied7(true);
    setTimeout(() => {
      setIsCopied7(false);
    }, 1000);
  };

  const [isCopied8, setIsCopied8] = useState(false);

  const onCopyText8 = () => {
    setIsCopied8(true);
    setTimeout(() => {
      setIsCopied8(false);
    }, 1000);
  };

  const [isCopied9, setIsCopied9] = useState(false);

  const onCopyText9 = () => {
    setIsCopied9(true);
    setTimeout(() => {
      setIsCopied9(false);
    }, 1000);
  };

  const [isCopied10, setIsCopied10] = useState(false);

  const onCopyText10 = () => {
    setIsCopied10(true);
    setTimeout(() => {
      setIsCopied10(false);
    }, 1000);
  };

  return (
    <>
      {/* HERO */}
      <section ref={brand} className="brand__hero">
        <img
          className="brand__mobile-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-4x5.jpg"
          alt="Baileys Vanilla Mint Shake Hero Mobile"
          title="Baileys Vanilla Mint Shake Hero Mobile"
        />
        <img
          className="brand__desktop-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-16x9.jpg"
          alt="Baileys Vanilla Mint Shake Hero Desktop"
          title="Baileys Vanilla Mint Shake Hero Desktop"
        />
        <Fancybox>
          <a
            rel="noreferrer"
            className="brand__play-btn"
            data-fancybox
            href="https://vimeo.com/791335616"
            aria-label="Play Baileys Vanilla Mint Shake sizzle reel."
          >
            <div className="brand__icon teal-gradient-bg">
              <FaIcons.FaPlay className=" white" />
            </div>
          </a>
        </Fancybox>
      </section>
      {/* INTRO */}
      <section ref={brandIntro} className="full brand__intro baileys-mint-bg">
        <div className="brand__intro-wrap">
          <h3 className="brand__intro-heading baileys-dark-mint">
            <span className="baileys-mint-alt">Introducing</span> <br />
            BAILEYS VANILLA MINT SHAKE <br />
            <span className="brand__intro-subtitle baileys-pink">
              LIMITED TIME ONLY
            </span>
          </h3>

          <p className="brand__intro-copy baileys-mint">
            Proudly Irish, yet classically American, Baileys Vanilla Mint Shake
            is the perfect way to celebrate the arrival of spring. Allow
            yourself to indulge in the delicious flavors of vanilla, mint and a
            hint of chocolate blended with the original taste of Baileys Irish
            Cream.
          </p>

          <p className="brand__intro-copy baileys-mint">
            A delightful treat to enjoy straight, in a shot, or mixed into an
            adult milkshake with flavor that calls for a cherry on top.
          </p>
          <p>
            <a
              rel="noreferrer"
              className="dil-btn"
              href="/styleguide"
              aria-label="Go to style guide"
            >
              Style Guide
            </a>
          </p>
        </div>
      </section>

      {/* SHARE BUTTONS */}
      <section ref={brandShare} className="full brand__share mint-gradient-bg">
        <div className="brand__share-wrap">
          <div className="brand__share-text">
            <h3 className="white">
              <span className="partial-op">Help build our</span>{" "}
              <span className="full-op">brands</span>{" "}
              <span className="partial-op">by</span>{" "}
              <span className="full-op">SHARING</span>{" "}
              <span className="partial-op">them on your</span>{" "}
              <span className="full-op">social media.</span>
            </h3>
          </div>
          <div className="brand__share-feature">
            <h4 className="brand__share-heading heading lime-green">
              Quick Share From Our Social Accounts
            </h4>
            {/* NEW SHARE BUTTONS */}
            <a
              rel="noreferrer"
              href="https://www.facebook.com/DiageoInnovation"
              target="_blank"
              aria-label="Share Baileys Vanilla Mint Shake from Facebook."
            >
              <i className="lime-green fab fa-facebook-f"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/DiageoInnovates"
              target="_blank"
              aria-label="Share Baileys Vanilla Mint Shake from Twitter."
            >
              <i className="lime-green fab fa-twitter"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/diageoinnovation/"
              target="_blank"
              aria-label="Share Baileys Vanilla Mint Shake from Instagram."
            >
              <i className="lime-green fab fa-instagram"></i>
            </a>

            {/* FORMER SHARE BUTTONS 
                  <a rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https://www.baileys.com/en-us/" aria-label="Share Baileys Vanilla Mint Shake on Facebook."><i className="lime-green fab fa-facebook"></i></a>
                  <a rel="noreferrer" href="https://twitter.com/intent/tweet?url=https://www.baileys.com/en-us/&text=" aria-label="Share Baileys Vanilla Mint Shake on Twitter."><i className="lime-green fab fa-twitter"></i></a>
                  <a rel="noreferrer" href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.baileys.com/en-us/" aria-label="Share Baileys Vanilla Mint Shake on LinkedIn."><i className="lime-green fab fa-linkedin-in"></i></a> */}
          </div>
        </div>
      </section>

      {/* VIDEO DOWNLOADS */}
      <section
        ref={brandVideo}
        id="video-downloads"
        className="full brand__video-downloads baileys-green-bg"
      >
        <h4 className="brand__video-download-heading heading baileys-mint-alt">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Videos.</span>
        </h4>
        <div className="brand__video-downloads-wrapper" video-rollover="1">
          <div className="brand__video-item">
            <div
              id="brand__video-item-1"
              className="brand__download-wrap brand__video-rollover baileys-mint-alt-bg"
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-1x1.jpg"
                alt="Baileys Vanilla Mint Shake 1x1 Thumbnail"
                title="Baileys Vanilla Mint Shake 1x1 Thumbnail"
              />

              {/* <div id="brand__video-preview-1" className="brand__preview-wrap">
                <iframe
                  title="brand__video-frame-1"
                  id="brand__video-frame-1"
                  className="brand__video-frame"
                  src="https://player.vimeo.com/video/745595799?api=1&loop=1&muted=1"
                  width="305px"
                  height="305px"
                  frameBorder="0"
                ></iframe>
              </div> */}

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335550/rendition/1080p/baileys-vanilla-mint-shake-1x1.mp4%20%281080p%29.mp4?loc=external&signature=59d81315ae674eb20473f588a5f742230f33ffc5aefb3d4510059070f7aab2a9"
                  download
                  aria-label="Download Baileys Vanilla Mint Shake 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335550"
                  aria-label="Preview Baileys Vanilla Mint Shake 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Square 1x1</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-2"
              className="brand__download-wrap brand__video-rollover baileys-mint-alt-bg"
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-9x16.jpg"
                alt="Baileys Vanilla Mint Shake 9x16 Thumbnail"
                title="Baileys Vanilla Mint Shake 9x16 Thumbnail"
              />

              {/* <div id="brand__video-preview-2" className="brand__preview-wrap">
                <iframe
                  title="brand__video-frame-2"
                  id="brand__video-frame-2"
                  className="brand__video-frame"
                  src="https://player.vimeo.com/video/745470723?api=1&loop=1&muted=1"
                  width="305px"
                  height="305px"
                  frameBorder="0"
                ></iframe>
              </div> */}

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335600/rendition/720p/baileys-vanilla-mint-shake-9x16%20%28720p%29.mp4?loc=external&signature=bbd62daebe2392d524663fc835b19abfe4cdc1be215c64716c3212e0e0e14a5b"
                  download
                  aria-label="Download Baileys Vanilla Mint Shake 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335600"
                  aria-label="Preview Baileys Vanilla Mint Shake 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Vertical 9x16</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook or Instagram Stories
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-3"
              className="brand__download-wrap brand__video-rollover baileys-mint-alt-bg"
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-4x5.jpg"
                alt="Baileys Vanilla Mint Shake 4x5 Thumbnail"
                title="Baileys Vanilla Mint Shake 4x5 Thumbnail"
              />

              {/* <div id="brand__video-preview-3" className="brand__preview-wrap">
                <iframe
                  title="brand__video-frame-3"
                  id="brand__video-frame-3"
                  className="brand__video-frame"
                  src="https://player.vimeo.com/video/745593845?api=1&loop=1&muted=1"
                  width="305px"
                  height="305px"
                  frameBorder="0"
                ></iframe>
              </div> */}

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335571/rendition/1080p/baileys-vanilla-mint-shake-4x5%20%281080p%29.mp4?loc=external&signature=d1fc309f3ba48e84c2e4b2b110d0a1167af09a5c856535f35255a92742182daa"
                  download
                  aria-label="Download Baileys Vanilla Mint Shake 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335571"
                  aria-label="Preview Baileys Vanilla Mint Shake 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Vertical 4x5</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-4"
              className="brand__download-wrap brand__video-rollover baileys-mint-alt-bg"
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-16x9.jpg"
                alt="Baileys Vanilla Mint Shake 16x9 Thumbnail"
                title="Baileys Vanilla Mint Shake 16x9 Thumbnail"
              />

              {/* <div id="brand__video-preview-4" className="brand__preview-wrap">
                <iframe
                  title="brand__video-frame-4"
                  id="brand__video-frame-4"
                  className="brand__video-frame"
                  src="https://player.vimeo.com/video/744374644?api=1&loop=1&muted=1"
                  width="305px"
                  height="305px"
                  frameBorder="0"
                ></iframe>
              </div> */}

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335616/rendition/720p/baileys-vanilla-mint-shake-16x9%20%28720p%29.mp4?loc=external&signature=379ec397e8a5128fe6803d54431cbc627ae8dc905f6b98295ab7ad92f45d22a4"
                  download
                  aria-label="Download Baileys Vanilla Mint Shake 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335616"
                  aria-label="Preview Baileys Vanilla Mint Shake 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Horizontal 16x9</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* IMAGE DOWNLOADS */}
      <section
        ref={brandImages}
        className="full brand__image-downloads baileys-mint-alt-bg-2"
      >
        <h4 className="brand__image-download-heading heading baileys-mint-alt">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Images.</span>
        </h4>

        <div className="brand__image-downloads-wrapper">
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap baileys-mint-alt-bg-3"
              href="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-1x1.jpg"
              aria-label="Download Baileys Vanilla Mint Shake 1x1 image"
              download
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-1x1.jpg"
                alt="Baileys Vanilla Mint Shake 1x1 thumbnail"
                title="Baileys Vanilla Mint Shake 1x1 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Square 1x1</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap baileys-mint-alt-bg-3"
              href="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-9x16.jpg"
              aria-label="Download Baileys Vanilla Mint Shake 9x16 image"
              download
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-9x16.jpg"
                alt="Baileys Vanilla Mint Shake 9x16 thumbnail"
                title="Baileys Vanilla Mint Shake 9x16 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 9x16</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook or Instagram Stories
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap baileys-mint-alt-bg-3"
              href="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-4x5.jpg"
              aria-label="Download Baileys Vanilla Mint Shake 4x5 image"
              download
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-4x5.jpg"
                alt="Baileys Vanilla Mint Shake 4x5 thumbnail"
                title="Baileys Vanilla Mint Shake 4x5 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 4x5</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap baileys-mint-alt-bg-3"
              href="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-16x9.jpg"
              aria-label="Download Baileys Vanilla Mint Shake 16x9 image"
              download
            >
              <img
                src="/assets/images/Baileys-Vanilla-Mint-Shake-Posts-16x9.jpg"
                alt="Baileys Vanilla Mint Shake 16x9 thumbnail"
                title="Baileys Vanilla Mint Shake 16x9 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Horizontal 16x9</strong>
              <br />
              <em className="baileys-mint-alt">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* HASHTAG SECTION */}
      <section
        ref={brandHashtags}
        className="full brand__hashtag-section baileys-mint-bg"
      >
        <div className="brand__required-hashtag">
          <h4 className="brand__hashtag-heading heading baileys-brown">
            REQUIRED HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">#IWorkForBaileys</strong>
              </p>
              <CopyToClipboard text="#IWorkForBaileys" onCopy={onCopyText}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText2}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied2 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="grab-hashtag">
          <h4 className="brand__hashtag-heading heading baileys-brown">
            OPTIONAL HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">#FromtheLab</strong>
              </p>
              <CopyToClipboard text="#FromtheLab" onCopy={onCopyText4}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied4 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">#CocktailCulture</strong>
              </p>
              <CopyToClipboard text="#CocktailCulture" onCopy={onCopyText5}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied5 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">
                  #BaileysVanillaMintShake
                </strong>
              </p>
              <CopyToClipboard
                text="#BaileysVanillaMintShake"
                onCopy={onCopyText6}
              >
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied6 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="baileys-mint">#Baileys</strong>
              </p>
              <CopyToClipboard text="#Baileys" onCopy={onCopyText7}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied7 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </section>

      {/* MESSAGE SECTION */}
      <section
        ref={brandCopy}
        className="full brand__message-section baileys-green-bg"
      >
        <h4 className="brand__message-heading heading">
          <span className="baileys-brown">
            NEED MESSAGE POINTS ABOUT THIS INNOVATION?
          </span>
        </h4>
        <div className="brand__message-wrapper">
          <div className="brand__copy-block brand__message">
            <h4 className="baileys-mint-alt">News Style</h4>

            <p className="baileys-mint-alt" id="NewsStyle">
              NEW this fall from Diageo Innovation - BAILEYS VANILLA MINT SHAKE
              - a bright and exciting celebration of spring. This indulgent
              drink treat is a perfect mix of vanilla, mint, and a hint of
              chocolate, blended with the original taste of Baileys Irish Cream.
              Proudly Irish, classically American. Available for a limited time.
            </p>
            <CopyToClipboard
              text="NEW this fall from Diageo Innovation - BAILEYS VANILLA MINT SHAKE - a bright and exciting celebration of spring. This indulgent drink treat is a perfect mix of vanilla, mint, and a hint of chocolate, blended with the original taste of Baileys Irish Cream. Proudly Irish, classically American. Available for a limited time."
              onCopy={onCopyText8}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied8 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="baileys-mint-alt">Casual</h4>

            <p className="baileys-mint-alt" id="Casual">
              Celebrate the arrival of spring with Baileys Vanilla Mint Shake,
              the latest release from Diageo Innovation. An exciting mix of
              vanilla, mint, and a hint of chocolate, blended with the original
              taste of Baileys Irish Cream. Just add a cherry on top. A proudly
              Irish, classically American flavor available for a limited time.
            </p>
            <CopyToClipboard
              text="Celebrate the arrival of spring with Baileys Vanilla Mint Shake, the latest release from Diageo Innovation. An exciting mix of vanilla, mint, and a hint of chocolate, blended with the original taste of Baileys Irish Cream. Just add a cherry on top. A proudly Irish, classically American flavor available for a limited time."
              onCopy={onCopyText9}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied9 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="baileys-mint-alt">Short & Fun</h4>

            <p className="baileys-mint-alt" id="ShortFun">
              Jump into spring with Baileys Vanilla Mint Shake. A proudly Irish,
              classically American flavor brought to perfection with a cherry on
              top. A delicious blend to celebrate new beginnings. Available for
              a limited time.
            </p>
            <CopyToClipboard
              text="Jump into spring with Baileys Vanilla Mint Shake. A proudly Irish, classically American flavor brought to perfection with a cherry on top. A delicious blend to celebrate new beginnings. Available for a limited time."
              onCopy={onCopyText10}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied10 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </section>

      {/* TIPS */}
      <Tips ref={brandTips} />

      {/* BRANDS SLIDER */}
      <BrandsSlider ref={brandSlider} />
    </>
  );
}

export default BaileysVanillaMintShake;
