import React, { useState } from "react";
import "../App.css";
import "./Accordion.css";

const Accordion = ({ title, content, accordionId }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <section
      id={accordionId}
      className={`accordion__container black-bg ${accordionId}`}
    >
      <div className="accordion">
        <div
          className="accordion__trigger"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="accordion__title">{title}</div>
          <div className="accordion__icon-indicator">
            {isActive ? "-" : "+"}
          </div>
        </div>
        {isActive && <div className="accordion__content">{content}</div>}
      </div>
    </section>
  );
};

export default Accordion;
