import React from "react";
import Flickity from "react-flickity-component";
import "flickity/dist/flickity.min.css";
import "./BrandsSlider.css";

const flickityOptions = {
  cellAlign: "left",
  contain: true,
  groupCells: true,
  draggable: true,
  lazyLoad: true,
  pageDots: true,
  prevNextButtons: true,
  // freeScroll: true,
  // selectedAttraction: 0.2,
  // friction: 0.8,
  // freeScrollFriction: 0.03,
  imagesLoaded: true,
  accessibility: true,
};

function BrandsSlider() {
  return (
    <>
      <section className="full brandsslider__related white-bg">
        <h4 className="brandsslider__related-heading heading black">
          See more Diageo Innovation creations from The LAB
        </h4>

        <Flickity
          className={"brandsslider__related-carousel"} // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          <div className="brandsslider__related-cell">
            <a
              href="/smirnoff-blue-raspberry-lemonade"
              aria-label="View the Smirnoff Blue Raspberry Lemonade page."
            >
              <img
                src="/assets/images/smirnoff-blue-raspberry-thumbnail.png"
                alt="Smirnoff Blue Raspberry Lemonade"
                title="Smirnoff Blue Raspberry Lemonade"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/captain-morgan-vita-coco"
              aria-label="View the Captain Morgan Vita Coco page."
            >
              <img
                src="/assets/images/captain-morgan-vita-coco-thumbnail.png"
                alt="Captain Morgan Vita Coco"
                title="Captain Morgan Vita Coco"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/buchanans-pineapple"
              aria-label="View the Buchanan's Pineapple page."
            >
              <img
                src="/assets/images/buchanans-pineapple-thumbnail.png"
                alt="Buchanan's Pineapple"
                title="Buchanan's Pineapple"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/baileys-vanilla-mint-shake"
              aria-label="View the Baileys Vanilla Mint Shake page."
            >
              <img
                src="/assets/images/baileys-vanilla-mint-shake-thumb.png"
                alt="Baileys Vanilla Mint Shake"
                title="Baileys Vanilla Mint Shake"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/baileys-smores"
              aria-label="View the Baileys S'mores page."
            >
              <img
                src="/assets/images/baileys-smores-thumb.png"
                alt="Baileys S'mores"
                title="Baileys S'mores"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/bulleit-crafted-cocktails"
              aria-label="View the Bulleit Crafted Cocktails page."
            >
              <img
                src="/assets/images/bulleit_thumb.png"
                alt="Bulleit Crafted Cocktails"
                title="Bulleit Crafted Cocktails"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/smirnoff-peach-lemonade"
              aria-label="View the Smirnoff Peach Lemonade page."
            >
              <img
                src="/assets/images/smirnoff-peach_thumb-crop-3.png"
                alt="Smirnoff Peach Lemonade"
                title="Smirnoff Peach Lemonade"
              />
            </a>
          </div>
          <div className="brandsslider__related-cell">
            <a
              href="/crown-royal-whisky-lemonade"
              aria-label="View the Crown Royal Whisky Lemonade page."
            >
              <img
                src="/assets/images/crown-whiskey-lemonade_thumb-5.png"
                alt="Crown Royal Whisky Lemonade"
                title="Crown Royal Whisky Lemonade"
              />
            </a>
          </div>
        </Flickity>
        <p style={{ textAlign: "center", marginTop: "3rem" }}>
          <a
            className="dil-btn reverse-btn"
            href="/styleguide"
            aria-label="Go to style guide"
          >
            Style Guide
          </a>
        </p>
      </section>
    </>
  );
}

export default BrandsSlider;
