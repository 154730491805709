import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Brands.css";
import "../App.css";
import * as FaIcons from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tips from "../components/Tips.js";
import BrandsSlider from "../components/BrandsSlider.js";
import Fancybox from "../components/Fancybox.js";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

function SmirnoffPeachLemonade() {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("smirnoff-peach-lemonade-page");
  }, []);

  // BRAND HERO ANIMATION
  const brand = useRef();
  const brandTl = useRef();

  useLayoutEffect(() => {
    const brandAnim = gsap.context(() => {
      brandTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".brand__brand-hero",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".brand__play-btn",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        );
    }, brand);
  }, []);

  // BRAND INTRO
  const brandIntro = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandIntro = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__intro",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandIntro.to(".brand__intro-wrap", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandIntro.scrollTrigger.kill();
    });
  }, []);

  // BRAND SHARE
  const brandShare = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandShare = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__share",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandShare.to(".brand__share-text", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandShare.scrollTrigger.kill();
    });
  }, []);

  // BRAND VIDEO
  const brandVideo = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandVideo = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__video-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandVideo.add("start");
      brandVideo.to(
        ".brand__video-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandVideo.to(
        ".brand__video-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandVideo.scrollTrigger.kill();
    });
  }, []);

  // BRAND IMAGES
  const brandImages = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandImages = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__image-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandImages.add("start");
      brandImages.to(
        ".brand__image-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandImages.to(
        ".brand__image-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandImages.scrollTrigger.kill();
    });
  }, []);

  // BRAND HASHTAGS
  const brandHashtags = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandHashtags = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__hashtag-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandHashtags.add("start");
      brandHashtags.to(
        ".brand__hashtag-heading",
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandHashtags.to(
        ".brand__hashtag",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandHashtags.scrollTrigger.kill();
    });
  }, []);

  // BRAND COPY
  const brandCopy = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandCopy = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__message-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandCopy.add("start");
      brandCopy.to(
        ".brand__message-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandCopy.to(
        ".brand__message",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandCopy.scrollTrigger.kill();
    });
  }, []);

  // BRAND TIPS
  const brandTips = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandTips = gsap.timeline({
        scrollTrigger: {
          trigger: ".tips__section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandTips.add("start");
      brandTips.to(
        ".tips__heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandTips.to(
        ".tips__block",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandTips.scrollTrigger.kill();
    });
  }, []);

  // BRAND SLIDER
  const brandSlider = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandSlider = gsap.timeline({
        scrollTrigger: {
          trigger: ".brandsslider__related",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandSlider.add("start");
      brandSlider.to(
        ".brandsslider__related-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related-cell a",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related .dil-btn",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandSlider.scrollTrigger.kill();
    });
  }, []);

  // copy functions
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const [isCopied2, setIsCopied2] = useState(false);

  const onCopyText2 = () => {
    setIsCopied2(true);
    setTimeout(() => {
      setIsCopied2(false);
    }, 1000);
  };

  const [isCopied3, setIsCopied3] = useState(false);

  const onCopyText3 = () => {
    setIsCopied3(true);
    setTimeout(() => {
      setIsCopied3(false);
    }, 1000);
  };

  const [isCopied4, setIsCopied4] = useState(false);

  const onCopyText4 = () => {
    setIsCopied4(true);
    setTimeout(() => {
      setIsCopied4(false);
    }, 1000);
  };

  const [isCopied5, setIsCopied5] = useState(false);

  const onCopyText5 = () => {
    setIsCopied5(true);
    setTimeout(() => {
      setIsCopied5(false);
    }, 1000);
  };

  const [isCopied6, setIsCopied6] = useState(false);

  const onCopyText6 = () => {
    setIsCopied6(true);
    setTimeout(() => {
      setIsCopied6(false);
    }, 1000);
  };

  const [isCopied7, setIsCopied7] = useState(false);

  const onCopyText7 = () => {
    setIsCopied7(true);
    setTimeout(() => {
      setIsCopied7(false);
    }, 1000);
  };

  const [isCopied8, setIsCopied8] = useState(false);

  const onCopyText8 = () => {
    setIsCopied8(true);
    setTimeout(() => {
      setIsCopied8(false);
    }, 1000);
  };

  const [isCopied9, setIsCopied9] = useState(false);

  const onCopyText9 = () => {
    setIsCopied9(true);
    setTimeout(() => {
      setIsCopied9(false);
    }, 1000);
  };

  const [isCopied10, setIsCopied10] = useState(false);

  const onCopyText10 = () => {
    setIsCopied10(true);
    setTimeout(() => {
      setIsCopied10(false);
    }, 1000);
  };

  return (
    <>
      {/* HERO */}
      <section ref={brand} className="brand__hero">
        <img
          className="brand__mobile-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Peach-Lemonade-Posts-4x5.jpg"
          alt="Smirnoff Peach Lemonade Hero Mobile"
          title="Smirnoff Peach Lemonade Hero Mobile"
        />
        <img
          className="brand__desktop-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Peach-Lemonade-Posts-16x9.jpg"
          alt="Smirnoff Peach Lemonade Hero Desktop"
          title="Smirnoff Peach Lemonade Hero Desktop"
        />
        <Fancybox>
          <a
            rel="noreferrer"
            className="brand__play-btn"
            data-fancybox
            href="https://vimeo.com/649593940/eafe268f5b"
            aria-label="Play Smirnoff Peach Lemonade sizzle reel."
          >
            <div className="brand__icon peach-gradient-bg">
              <FaIcons.FaPlay className="white" />
            </div>
          </a>
        </Fancybox>
      </section>

      {/* INTRO */}
      <section ref={brandIntro} className="full brand__intro dark-blue-bg">
        <div className="brand__intro-wrap">
          <h3 className="brand__intro-heading peach">
            <span className="tan">Introducing</span> <br />
            SMIRNOFF PEACH LEMONADE.
          </h3>

          <p className="brand__intro-copy">
            One of the most popular vodka flavor trends in America features
            bright peach notes over a refreshing lemonade flavor that’s just
            right for celebrating fun days outdoors or warm summer nights. It’s{" "}
            <strong>quality Smirnoff Vodka</strong> infused with natural flavors
            perfect for taste-bud bursting cocktails. Enjoy as a shot, or in
            iced tea, lemonade, or our signature Peach Tea Lemonade.
          </p>

          <p>
            <a
              rel="noreferrer"
              className="dil-btn"
              href="/styleguide"
              aria-label="Go to style guide"
            >
              Style Guide
            </a>
          </p>
        </div>
      </section>

      {/* SHARE BUTTONS */}
      <section ref={brandShare} className="full brand__share peach-gradient-bg">
        <div className="brand__share-wrap">
          <div className="brand__share-text">
            <h3 className="white">
              <span className="partial-op">Help build our</span>{" "}
              <span className="full-op">brands</span>{" "}
              <span className="partial-op">by</span>{" "}
              <span className="full-op">SHARING</span>{" "}
              <span className="partial-op">them on your</span>{" "}
              <span className="full-op">social media.</span>
            </h3>
          </div>
          <div className="brand__share-feature">
            <h4 className="brand__share-heading heading lime-green">
              Quick Share From Our Social Accounts
            </h4>
            {/* NEW SHARE BUTTONS */}
            <a
              rel="noreferrer"
              href="https://www.facebook.com/DiageoInnovation"
              target="_blank"
              aria-label="Share Smirnoff Peach Lemonade from Facebook."
            >
              <i className="lime-green fab fa-facebook-f"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/DiageoInnovates"
              target="_blank"
              aria-label="Share Smirnoff Peach Lemonade from Twitter."
            >
              <i className="lime-green fab fa-twitter"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/diageoinnovation/"
              target="_blank"
              aria-label="Share Smirnoff Peach Lemonade from Instagram."
            >
              <i className="lime-green fab fa-instagram"></i>
            </a>

            {/* FORMER SHARE BUTTONS 
                  <a rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https://www.baileys.com/en-us/" aria-label="Share Smirnoff Peach Lemonade on Facebook."><i className="lime-green fab fa-facebook"></i></a>
                  <a rel="noreferrer" href="https://twitter.com/intent/tweet?url=https://www.baileys.com/en-us/&text=" aria-label="Share Smirnoff Peach Lemonade on Twitter."><i className="lime-green fab fa-twitter"></i></a>
                  <a rel="noreferrer" href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.baileys.com/en-us/" aria-label="Share Smirnoff Peach Lemonade on LinkedIn."><i className="lime-green fab fa-linkedin-in"></i></a> */}
          </div>
        </div>
      </section>

      {/* VIDEO DOWNLOADS */}
      <section
        ref={brandVideo}
        id="video-downloads"
        className="full brand__video-downloads gray-bg"
      >
        <h4 className="brand__video-download-heading heading peach">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Videos.</span>
        </h4>
        <div className="brand__video-downloads-wrapper" video-rollover="1">
          <Fancybox>
            <div className="brand__video-item">
              <div
                id="brand__video-item-1"
                className="brand__download-wrap brand__video-rollover black-bg"
              >
                <img
                  src="/assets/images/Peach-Lemonade-Posts-1x1.jpg"
                  alt="Smirnoff Peach Lemonade 1x1 Thumbnail"
                  title="Smirnoff Peach Lemonade 1x1 Thumbnail"
                />

                {/* <div
                  id="brand__video-preview-1"
                  className="brand__preview-wrap"
                >
                  <iframe
                    title="brand__video-frame-1"
                    id="brand__video-frame-1"
                    className="brand__video-frame"
                    src="https://player.vimeo.com/video/666170510?api=1&loop=1&muted=1"
                    width="305px"
                    height="305px"
                    frameBorder="0"
                  ></iframe>
                </div> */}

                <div className="brand__video-item-btns">
                  <a
                    rel="noreferrer"
                    href="https://player.vimeo.com/progressive_redirect/download/666170510/rendition/1080p/smirnoff_peach_lemonade_-_1x1_-_logos_intro.mp4%20%281080p%29.mp4?loc=external&signature=5daa841e31103f9e15bfb166fbf82517f470dfb56d5a254d8db5f0c3dd26abe6"
                    download
                    aria-label="Download Smirnoff Peach Lemonade 1x1 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaArrowDown className="white" />
                      <span>Download</span>
                    </div>
                  </a>

                  <a
                    data-fancybox
                    href="https://vimeo.com/666170510"
                    aria-label="Preview Smirnoff Peach Lemonade 1x1 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaEye className="white" />
                      <span>Preview</span>
                    </div>
                  </a>
                </div>
              </div>
              <p>
                <strong>Square 1x1</strong>
                <br />
                <em>Facebook, LinkedIn, or Instagram Posts</em>
              </p>
            </div>
            <div className="brand__video-item">
              <div
                id="brand__video-item-2"
                className="brand__download-wrap brand__video-rollover black-bg"
              >
                <img
                  src="/assets/images/Peach-Lemonade-Posts-9x16.jpg"
                  alt="Smirnoff Peach Lemonade 9x16 Thumbnail"
                  title="Smirnoff Peach Lemonade 9x16 Thumbnail"
                />

                {/* <div
                  id="brand__video-preview-2"
                  className="brand__preview-wrap"
                >
                  <iframe
                    title="brand__video-frame-2"
                    id="brand__video-frame-2"
                    className="brand__video-frame"
                    src="https://player.vimeo.com/video/666170540?api=1&loop=1&muted=1"
                    width="305px"
                    height="305px"
                    frameBorder="0"
                  ></iframe>
                </div> */}

                <div className="brand__video-item-btns">
                  <a
                    rel="noreferrer"
                    href="https://player.vimeo.com/progressive_redirect/download/666170540/rendition/720p/smirnoff_peach_lemonade_-_9x16_-_logos_intro.mp4%20%28720p%29.mp4?loc=external&signature=700d84459f666b188d337b865287b6c7f1a5f086b5aa3554eb8ac505d625cc5e"
                    download
                    aria-label="Download Smirnoff Peach Lemonade 9x16 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaArrowDown className="white" />
                      <span>Download</span>
                    </div>
                  </a>

                  <a
                    data-fancybox
                    href="https://vimeo.com/666170540"
                    aria-label="Preview Smirnoff Peach Lemonade 9x16 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaEye className="white" />
                      <span>Preview</span>
                    </div>
                  </a>
                </div>
              </div>
              <p>
                <strong>Vertical 9x16</strong>
                <br />
                <em>Facebook or Instagram Stories</em>
              </p>
            </div>
            <div className="brand__video-item">
              <div
                id="brand__video-item-3"
                className="brand__download-wrap brand__video-rollover black-bg"
              >
                <img
                  src="/assets/images/Peach-Lemonade-Posts-4x5.jpg"
                  alt="Smirnoff Peach Lemonade 4x5 Thumbnail"
                  title="Smirnoff Peach Lemonade 4x5 Thumbnail"
                />

                {/* <div
                  id="brand__video-preview-3"
                  className="brand__preview-wrap"
                >
                  <iframe
                    title="brand__video-frame-3"
                    id="brand__video-frame-3"
                    className="brand__video-frame"
                    src="https://player.vimeo.com/video/666170529?api=1&loop=1&muted=1"
                    width="305px"
                    height="305px"
                    frameBorder="0"
                  ></iframe>
                </div> */}

                <div className="brand__video-item-btns">
                  <a
                    rel="noreferrer"
                    href="https://player.vimeo.com/progressive_redirect/download/666170529/rendition/1080p/smirnoff_peach_lemonade_-_4x5_-_logos_intro.mp4%20%281080p%29.mp4?loc=external&signature=0978dd65e2a542b8a8797ff501268c745e203784e4881b95dc2a6422c3c6835d"
                    download
                    aria-label="Download Smirnoff Peach Lemonade 4x5 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaArrowDown className="white" />
                      <span>Download</span>
                    </div>
                  </a>

                  <a
                    data-fancybox
                    href="https://vimeo.com/666170529"
                    aria-label="Preview Smirnoff Peach Lemonade 4x5 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaEye className="white" />
                      <span>Preview</span>
                    </div>
                  </a>
                </div>
              </div>
              <p>
                <strong>Vertical 4x5</strong>
                <br />
                <em>Facebook, LinkedIn, or Instagram Posts</em>
              </p>
            </div>
            <div className="brand__video-item">
              <div
                id="brand__video-item-4"
                className="brand__download-wrap brand__video-rollover black-bg"
              >
                <img
                  src="/assets/images/Peach-Lemonade-Posts-16x9.jpg"
                  alt="Smirnoff Peach Lemonade 16x9 Thumbnail"
                  title="Smirnoff Peach Lemonade 16x9 Thumbnail"
                />

                {/* <div
                  id="brand__video-preview-4"
                  className="brand__preview-wrap"
                >
                  <iframe
                    title="brand__video-frame-4"
                    id="brand__video-frame-4"
                    className="brand__video-frame"
                    src="https://player.vimeo.com/video/649593940?api=1&loop=1&muted=1"
                    width="305px"
                    height="305px"
                    frameBorder="0"
                  ></iframe>
                </div> */}

                <div className="brand__video-item-btns">
                  <a
                    rel="noreferrer"
                    href="https://player.vimeo.com/progressive_redirect/download/649593940/rendition/720p/smirnoff_peach_lemonade_-_16x9_-_logos_intro_-_r1a%20%28720p%29.mp4?loc=external&signature=4219aa92f27978dfdffd04be30d8a3b044f8ce239a35248a00ea3d0ce4bec579"
                    download
                    aria-label="Download Smirnoff Peach Lemonade 16x9 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaArrowDown className="white" />
                      <span>Download</span>
                    </div>
                  </a>

                  <a
                    data-fancybox
                    href="https://vimeo.com/649593940"
                    aria-label="Preview Smirnoff Peach Lemonade 16x9 Video"
                  >
                    <div className="brand__video-download-preview">
                      <FaIcons.FaEye className="white" />
                      <span>Preview</span>
                    </div>
                  </a>
                </div>
              </div>
              <p>
                <strong>Horizontal 16x9</strong>
                <br />
                <em>Facebook, LinkedIn, or Twitter Posts</em>
              </p>
            </div>
          </Fancybox>
        </div>
      </section>

      {/* IMAGE DOWNLOADS */}
      <section
        ref={brandImages}
        className="full brand__image-downloads black-bg"
      >
        <h4 className="brand__image-download-heading heading turquoise">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Images.</span>
        </h4>

        <div className="brand__image-downloads-wrapper">
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap gray-bg"
              href="/assets/images/Peach-Lemonade-Posts-1x1.jpg"
              aria-label="Download Smirnoff Peach Lemonade 1x1 image"
              download
            >
              <img
                src="/assets/images/Peach-Lemonade-Posts-1x1.jpg"
                alt="Smirnoff Peach Lemonade 1x1 thumbnail"
                title="Smirnoff Peach Lemonade 1x1 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Square 1x1</strong>
              <br />
              <em>Facebook, LinkedIn, or Instagram Posts</em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap gray-bg"
              href="/assets/images/Peach-Lemonade-Posts-9x16.jpg"
              aria-label="Download Smirnoff Peach Lemonade 9x16 image"
              download
            >
              <img
                src="/assets/images/Peach-Lemonade-Posts-9x16.jpg"
                alt="Smirnoff Peach Lemonade 9x16 thumbnail"
                title="Smirnoff Peach Lemonade 9x16 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 9x16</strong>
              <br />
              <em>Facebook or Instagram Stories</em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap gray-bg"
              href="/assets/images/Peach-Lemonade-Posts-4x5.jpg"
              aria-label="Download Smirnoff Peach Lemonade 4x5 image"
              download
            >
              <img
                src="/assets/images/Peach-Lemonade-Posts-4x5.jpg"
                alt="Smirnoff Peach Lemonade 4x5 thumbnail"
                title="Smirnoff Peach Lemonade 4x5 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 4x5</strong>
              <br />
              <em>Facebook, LinkedIn, or Instagram Posts</em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap gray-bg"
              href="/assets/images/Peach-Lemonade-Posts-16x9.jpg"
              aria-label="Download Smirnoff Peach Lemonade 16x9 image"
              download
            >
              <img
                src="/assets/images/Peach-Lemonade-Posts-16x9.jpg"
                alt="Smirnoff Peach Lemonade 16x9 thumbnail"
                title="Smirnoff Peach Lemonade 16x9 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Horizontal 16x9</strong>
              <br />
              <em>Facebook, LinkedIn, or Twitter Posts</em>
            </p>
          </div>
        </div>
      </section>

      {/* HASHTAG SECTION */}
      <section
        ref={brandHashtags}
        className="full brand__hashtag-section peach-gradient-bg"
      >
        <div className="brand__required-hashtag">
          <h4 className="brand__hashtag-heading heading dark-blue">
            REQUIRED HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#IWorkForSmirnoff</strong>
              </p>
              <CopyToClipboard text="#IWorkForSmirnoff" onCopy={onCopyText}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText2}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied2 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="grab-hashtag">
          <h4 className="brand__hashtag-heading heading dark-blue">
            OPTIONAL HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#FromtheLab</strong>
              </p>
              <CopyToClipboard text="#FromtheLab" onCopy={onCopyText3}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied3 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#CocktailCulture</strong>
              </p>
              <CopyToClipboard text="#CocktailCulture" onCopy={onCopyText4}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied4 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#Vodka</strong>
              </p>
              <CopyToClipboard text="#Vodka" onCopy={onCopyText5}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied5 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#SmirnoffPeachLemonade</strong>
              </p>
              <CopyToClipboard
                text="#SmirnoffPeachLemonade"
                onCopy={onCopyText6}
              >
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied6 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#Smirnoff</strong>
              </p>
              <CopyToClipboard text="#Smirnoff" onCopy={onCopyText7}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied7 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </section>

      {/* MESSAGE SECTION */}
      <section ref={brandCopy} className="full brand__message-section black-bg">
        <h4 className="brand__message-heading heading">
          <span className="turquoise">
            NEED MESSAGE POINTS ABOUT THIS INNOVATION?
          </span>
        </h4>
        <div className="brand__message-wrapper">
          <div className="brand__copy-block brand__message">
            <h4>News Style</h4>

            <p id="NewsStyle">
              NEW from Diageo Innovation - SMIRNOFF PEACH LEMONADE - one of
              today’s most popular vodka flavor trends - bright peach notes over
              a refreshing lemonade flavor that’s just right for celebrating fun
              days outdoors or warm summer nights. Enjoy as a shot, or in iced
              tea, lemonade, or our signature Peach Tea Lemonade.
            </p>
            <CopyToClipboard
              text="NEW from Diageo Innovation - SMIRNOFF PEACH LEMONADE - one of today’s most popular vodka flavor trends - bright peach notes over a refreshing lemonade flavor that’s just right for celebrating fun days outdoors or warm summer nights. Enjoy as a shot, or in iced tea, lemonade, or our signature Peach Tea Lemonade."
              onCopy={onCopyText8}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied8 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4>Casual</h4>

            <p id="Casual">
              Add sizzle to your summer with this new creation from Diageo
              Innovation. SMIRNOFF PEACH LEMONADE, one of today’s hottest vodka
              flavor trends, marries bright peach notes to a refreshing lemonade
              taste that is sure to lively up your summer party times. Pop a
              shot, or mix it up with tea, lemonade, or our signature Peach Tea
              Lemonade.
            </p>
            <CopyToClipboard
              text="Add sizzle to your summer with this new creation from Diageo Innovation. SMIRNOFF PEACH LEMONADE, one of today’s hottest vodka flavor trends, marries bright peach notes to a refreshing lemonade taste that is sure to lively up your summer party times. Pop a shot, or mix it up with tea, lemonade, or our signature Peach Tea Lemonade."
              onCopy={onCopyText9}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied9 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4>Short & Fun</h4>

            <p id="ShortFun">
              What’s the summer’s coolest vodka flavor? SMIRNOFF PEACH LEMONADE
              marries bright peach notes with a refreshing lemonade taste to add
              a flavor explosion to your summer cocktails. Pop a shot, or mix
              with tea, lemonade, or both!
            </p>
            <CopyToClipboard
              text="What’s the summer’s coolest vodka flavor? SMIRNOFF PEACH LEMONADE marries bright peach notes with a refreshing lemonade taste to add a flavor explosion to your summer cocktails. Pop a shot, or mix with tea, lemonade, or both!"
              onCopy={onCopyText10}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied10 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </section>

      {/* TIPS */}
      <Tips ref={brandTips} />

      {/* BRANDS SLIDER */}
      <BrandsSlider ref={brandSlider} />
    </>
  );
}

export default SmirnoffPeachLemonade;
