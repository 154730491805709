import React from "react";
import "./Tips.css";
import Fancybox from "../components/Fancybox.js";

function Tips() {
  return (
    <>
      {/* TIPS */}
      <section className="full tips__section gray-bg">
        <h4 className="tips__heading heading">TIPS FOR POSTING</h4>
        <div className="tips__wrapper">
          <div className="tips__block">
            <Fancybox>
              <button data-fancybox data-src="#how">
                How
              </button>
            </Fancybox>
            <p id="how" style={{ display: "none", maxWidth: "500px" }}>
              <strong>How</strong>
              <br />
              Please adhere to DIAGEO Social Media Guidelines when posting so we
              can speak with a unified voice and let fans of our brands know how
              much we appreciate them.
            </p>
          </div>
          <div className="tips__block">
            <Fancybox>
              <button data-fancybox data-src="#where">
                Where
              </button>
            </Fancybox>
            <p id="where" style={{ display: "none", maxWidth: "500px" }}>
              <strong>Where</strong>
              <br />
              Post to your favorite social media platforms, Facebook, Twitter,
              LinkedIn or Instagram. We’ve provided easy sharing links and
              graphics on this page. So post away!
            </p>
          </div>
          <div className="tips__block">
            <Fancybox>
              <button data-fancybox data-src="#when">
                When
              </button>
            </Fancybox>
            <p id="when" style={{ display: "none", maxWidth: "500px" }}>
              <strong>When</strong>
              <br />
              As soon as possible! These are “sneak previews” of NEW creations
              from the DIAGEO Innovation Lab. So the quicker you get the word
              out, the fresher it will be.
            </p>
          </div>
          <div className="tips__block">
            <Fancybox>
              <button data-fancybox data-src="#why">
                Why
              </button>
            </Fancybox>
            <p id="why" style={{ display: "none", maxWidth: "500px" }}>
              <strong>Why</strong>
              <br />
              Give your family and friends a “first look” at the latest
              creations from DIAGEO Innovation Lab. This helps us introduce our
              new products to the market and build successful launches.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Tips;
