import React from "react";
import { gsap } from "gsap";
import "./Header.css";
import Menu from "./Menu.js";

const { useLayoutEffect, useRef } = React;

function Header() {
  // HEADER ANIMATION
  const header = useRef();
  const headerTl = useRef();

  useLayoutEffect(() => {
    let headerAnim = gsap.context(() => {
      headerTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".header__logo img",
          {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".header__tagline p",
          {
            y: 0,
            autoAlpha: 1,
            delay: 0.5,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".header__the-lab img",
          {
            y: 0,
            autoAlpha: 1,
            delay: 1,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".header__diageo-menu-btn",
          {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            ease: "power4.inOut",
          },
          "start"
        );
    }, header);
  }, []);

  return (
    <header ref={header}>
      <div className="header__logo">
        <a className="header__home-link" href="/">
          <img
            alt="Diageo Innovation Logo"
            className="header__logo-img"
            src="/assets/images/diageo-innovation-logo.svg"
          />
        </a>
      </div>
      <div className="header__tagline">
        <p>
          Presents these exciting <span className="white">new</span> creations
          from
        </p>
      </div>
      <div className="header__lab-logo">
        <a className="header__the-lab" href="/">
          <img
            alt="Diageo Innovation The Lab"
            className="header__lab-logo-img"
            src="/assets/images/The-Lab-Horizontal-Reversed-RGB.svg"
          />
        </a>

        <Menu />
      </div>
    </header>
  );
}

export default Header;
