import React from "react";
import "./Notice.css";
import { useOktaAuth } from "@okta/okta-react";

function Notice() {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    await oktaAuth.signOut();
  };

  const signInBtn = authState.isAuthenticated ? (
    <button className="sso-btn" onClick={logout}>
      Logout
    </button>
  ) : (
    <button className="sso-btn" onClick={login}>
      Sign In
    </button>
  );

  return authState.isAuthenticated ? (
    // content to show when signed in
    <p
      style={{
        textAlign: "center",
        color: "red",
        padding: "25px",
        display: "none",
      }}
    >
      Private Test page
    </p>
  ) : (
    // content to show when no signed in
    <>
      <div className="half sso-box">
        <div className="sso-wrap">
          <h1>Welcome to DIAGEO INNOVATIONS Social Sharing Portal</h1>
          <p>
            Here you’ll find all the sharing tools you’ll need - including
            videos, graphics, hashtags, copy and more! To access the tools,
            login using your Okta Single Sign On account below to keep our new
            product info safe and secure.
          </p>
          {signInBtn}
          <p className="sso-help">
            <a
              href="https://diageo.okta.com/signin/forgot-password"
              target="_blank"
              rel="noreferrer"
              className="white"
            >
              Forgot password?
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Notice;
